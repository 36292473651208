// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}visit/temp/pageList`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}decoration/export/guide`
// 更新表单
const uploadGuideURL = `${API_CONFIG.butlerBaseURL}visit/temp/close`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList?ismodal=1`
// 获取运营项目组
const getOperateRegionsURL = `${API_CONFIG.baseURL}serverCodewordAction!getOperateRegions.action`
// 获取阶段状态
const getProjectTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getProjectTypes.action`
// 项目类型
const getRoomTypesURL = `${API_CONFIG.baseURL}serverCodewordAction!getRoomTypes.action`

const addBaseInfoURL = `${API_CONFIG.butlerBaseURL}visit/temp/add/info`

const updateBaseInfoURL = `${API_CONFIG.butlerBaseURL}visit/temp/update/info`

const getVisitTempDetailDataURL = `${API_CONFIG.butlerBaseURL}visit/temp/detail`

const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`

const votePreviewURL = `${API_CONFIG.h5BaseURL}h5/community/newVote/index.html#/preview?voteId=`

const saveQueURL = `${API_CONFIG.butlerBaseURL}visit/temp/add/question`
export {
  getListURL,
  exportListURL,
  uploadGuideURL,
  getCommunityListURL,
  getOperateRegionsURL,
  getProjectTypesURL,
  getRoomTypesURL,
  getVisitTempDetailDataURL,
  addBaseInfoURL,
  uploadURL,
  votePreviewURL,
  saveQueURL,
  updateBaseInfoURL
}
