// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}visit/plan/pageList`
// 获取项目列表
const getCommunityListURL = `${API_CONFIG.controlBaseURL}community/getCommunityList?ismodal=1`


const updateURL = `${API_CONFIG.butlerBaseURL}visit/plan/update`


const saveQueURL = `${API_CONFIG.butlerBaseURL}visit/plan/add`

const delPlanURL = `${API_CONFIG.butlerBaseURL}visit/plan/del`

const generateTaskURL = `${API_CONFIG.butlerBaseURL}visit/plan/generate`

const getHouseListURL = `${API_CONFIG.shopBaseURL}communityRoom/listCommunityRoom`

const getUserListURL = `${API_CONFIG.butlerBaseURL}user/search/community/username`

const getPlanDetailURL = `${API_CONFIG.butlerBaseURL}visit/plan/detail`
export {
  getListURL,
  getCommunityListURL,
  updateURL,
  saveQueURL,
  delPlanURL,
  generateTaskURL,
  getHouseListURL,
  getUserListURL,
  getPlanDetailURL
}
