<template>
  <div class='OwnerMgrForm-container' >
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail :span="24">
        <col2-block :title="title">
          <el-form-item
            label="所属项目"
            :rules="[
              { required: true, message: '请选择所属项目', trigger: 'change' },
            ]"
            prop="communityId"
          >
            <v-select2 v-model="form.communityId"  v-bind="communityIdConfig"></v-select2>
          </el-form-item>
          <el-form-item
            label="计划名称"
            :rules="[
              { required: true, message: '计划名称不能为空', trigger: 'blur' },
            ]"
            prop="planName"
          >
            <v-input  v-model="form.planName"></v-input>
          </el-form-item>
          <el-form-item label="拜访模版"
                        :rules="[
              { required: true, message: '请选择拜访模版', trigger: 'change' },
            ]"
                        prop="visitTempId">
            <el-select v-model="form.visitTempId" placeholder="请选择">
              <el-option
                v-for="item in visitTempList"
                :key="item.id"
                :label="item.tempName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="拜访区域" :rules="[{ required: true, message: '请选择服务区域', trigger: 'blur' }]"  prop="houseIdList">
            <chosen-list-panel :list.sync="form.houseIdList" @select="onSelect" />
          </el-form-item>
          <el-form-item label="负责人"
                        :rules="[
              { required: true, message: '负责人不能为空', trigger: 'change' },
            ]"
                        prop="chargeUserId">
            <v-select2
              v-model="form.chargeUserId"
              v-bind="userBind"
              :width="220"
              :subjoin="{ communityId: form.communityId }"
            />
          </el-form-item>
          <el-form-item label="拜访时间"
                        :rules="[
              { required: true, message: '请选择拜访时间', trigger: 'change' },
            ]"
                        prop="endTime">
            <v-datepicker type="rangedatetimer"
                          :startTime.sync="form.startTime"
                          :endTime.sync="form.endTime"
                          :minDate="minDate"></v-datepicker>
          </el-form-item>


        </col2-block>
      </col2-detail>
      <multi-select2
        ref="multiSelect"
        :searchUrl="houseSearchUrl"
        :headers="tableHeaders"
        :searchParams="searchParams"
        :responseParams="responseParams"
        :responseKey="responseKey"
        :backFill.sync="form.houseIdList"
        :handleData="handleData"
        :maxCount="5000"
        :isShow.sync="visible">
        <template #searchSlot>
          <v-select label="使用状态" v-model="searchParams.usingStatus" :options="usingStatusOps"></v-select>
          <v-input label="苑" v-model="searchParams.blockName"></v-input>
          <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
          <v-input label="单元" v-model="searchParams.unit"></v-input>
          <v-input label="室" v-model="searchParams.room"></v-input>
          <v-select label="房屋类型" v-model="searchParams.houseType" :options="houseTypeOps"></v-select>
          <v-select label="房屋状态" v-model="searchParams.roomStatus" :options="roomStatusOps"></v-select>
        </template>
      </multi-select2>
    </form-panel>
  </div>
</template>
<script>
import * as allVisitPlanFromUrl from './api'
import * as  allVisitOwnerFromUrl from '../visitOwnerConfig/api'
import { Col2Detail, Col2Block, MultiSelect2, ChosenListPanel,TabsPanel  } from 'components/bussiness'
import { houseTypeMap, roomStatusMap, roomStatusOps, usingStatusOps, usingStatusMap } from 'views/project/projectHouseConfig/validHouse/map'
import {communityParams} from "common/select2Params";
export default {
  name: 'VisitPlanConfigForm',
  components: {
    MultiSelect2,
    ChosenListPanel,
    TabsPanel,
    Col2Block,
    Col2Detail
  },
  props:{
    editQuery:Object
  },
  data () {
    return {
      userBind: {
        searchUrl: allVisitPlanFromUrl.getUserListURL,
        request: {
          text: 'username',
          value: 'id'
        },
        response: {
          text: 'username',
          value: 'id'
        }
      },
      minDate: new Date(),
      title:'',
      usingStatusOps: usingStatusOps(1),
      houseTypeOps: [],
      roomStatusOps: roomStatusOps(1),
      submitConfig: {
        submitUrl: allVisitPlanFromUrl.saveQueURL,
        queryUrl: '',
        submitMethod: 'post'
      },
      form:{
        communityId: undefined,
        planName: '',
        chargeUserId: '',
        houseIdList: [],
        startTime: undefined,
        endTime: undefined
      },
      visible:false,
      activeName: 'baseInfoForm',
      communityIdConfig: communityParams,
      houseSearchUrl: allVisitPlanFromUrl.getHouseListURL,
      tableHeaders: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockName',
          label: '苑'
        },
        {
          prop: 'buildingNumber',
          label: '幢'
        },
        {
          prop: 'unit',
          label: '单元'
        },
        {
          prop: 'room',
          label: '室'
        },
        {
          prop: 'houseTypeText',
          label: '房屋类型',
          formatter (row) {
            return houseTypeMap[row.houseType]
          }
        },
        {
          prop: 'roomStatusText',
          label: '房屋状态',
          formatter: row => roomStatusMap[row.roomStatus]
        },
        {
          prop: 'statusText',
          label: '使用状态',
          formatter: row => usingStatusMap[row.status]
        }
      ],
      searchParams: {
        usingStatus: 1,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        houseType: undefined,
        roomStatus: undefined,
        communityId: undefined
      },
      responseParams: {
        id: 'id',
        name: 'text'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      visitConfig: {
        data: ''
      },
      userId: {
        id: ''
      },
      houseId: {
        id: ''
      },
      id:'',
      visitTempList:[],
      tabs: [],
      activeLabel: '基础信息',
      communityIdOps: [],
      vdomId: 1,
      query:{}
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    let { id } = this.query
    if (id) {
      this.title = '编辑拜访计划'
      this.submitConfig.submitUrl = allVisitPlanFromUrl.updateURL
      this.$axios({
        url: allVisitPlanFromUrl.getPlanDetailURL+"?id="+id,
        method: 'GET',
      }).then(res => {
        this.form = {
          id:id,
          communityId:res.data.communityId,
          planName:res.data.planName,
          visitTempId:res.data.visitTempId,
          chargeUserId:res.data.chargeUserId,
          startTime:res.data.startTime ? res.data.startTime :'',
          endTime:res.data.endTime ? res.data.endTime :'',
          houseIdList:res.data.roomList.map(item => {
            return {
              ...item,
              text: item.roomNumName,
              id: item.roomNumId
            }
          })
        }
        this.$refs.formPanel.getData({
          userId:res.data.chargeUserId,
          communityId:res.data.communityId
        })
      })
    }else {
      this.title = '新增拜访计划'
    }
    this.$axios({
      url: allVisitOwnerFromUrl.getListURL+"?pageSize=100&status=0",
      method: 'GET',
    }).then(res => {
      this.visitTempList = res.data.resultList
    })
  },
  methods: {
    update (data) {
      if (data.length) {
        this.form.roomList = data.map(item => {
          console.log(item)
          return {
            ...item,
            text: item.roomNumName,
            id: String(item.roomNumId)
          }
        })
        this.form.userId = data[0].userId
        this.communityName = data[0].communityName
      }
    },
    async goBack () {
      let isOk = await this.$confirm(
        '您当前输入信息还未被保存，确定离开当前页面吗？'
      )
      if (isOk) {
        this.$router.go(-1)
      }
    },
    submitBefore (data) {
      data.houseIdList = data.houseIdList.map(item => item.id)
      return true
    },
    submitSuccess (houseIds) {

        return true
    },
    onSelect () {
      if (!this.form.communityId) {
        this.$message.info('请先选择项目')
        return false
      }
      this.searchParams.communityId = this.form.communityId
      this.visible = true
      this.$nextTick(() => {
        this.$refs.multiSelect.searchData()
      })
    },
    handleData (data) {
      // 处理结果项目显示字符
      let arr = [data.blockName, data.buildingNumber, data.unit, data.room]
      data.text = arr.filter(val => val).join('-')
    },
  }
}
</script>
