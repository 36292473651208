var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "OwnerMgrForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
            },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "headerSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "返回" },
                      on: { click: _vm.goBack },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: _vm.title } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "change",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityIdConfig,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "计划名称",
                        rules: [
                          {
                            required: true,
                            message: "计划名称不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "planName",
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.planName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "planName", $$v)
                          },
                          expression: "form.planName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "拜访模版",
                        rules: [
                          {
                            required: true,
                            message: "请选择拜访模版",
                            trigger: "change",
                          },
                        ],
                        prop: "visitTempId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.visitTempId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "visitTempId", $$v)
                            },
                            expression: "form.visitTempId",
                          },
                        },
                        _vm._l(_vm.visitTempList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.tempName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "拜访区域",
                        rules: [
                          {
                            required: true,
                            message: "请选择服务区域",
                            trigger: "blur",
                          },
                        ],
                        prop: "houseIdList",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: { list: _vm.form.houseIdList },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.form, "houseIdList", $event)
                          },
                          select: _vm.onSelect,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "负责人",
                        rules: [
                          {
                            required: true,
                            message: "负责人不能为空",
                            trigger: "change",
                          },
                        ],
                        prop: "chargeUserId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              width: 220,
                              subjoin: { communityId: _vm.form.communityId },
                            },
                            model: {
                              value: _vm.form.chargeUserId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "chargeUserId", $$v)
                              },
                              expression: "form.chargeUserId",
                            },
                          },
                          "v-select2",
                          _vm.userBind,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "拜访时间",
                        rules: [
                          {
                            required: true,
                            message: "请选择拜访时间",
                            trigger: "change",
                          },
                        ],
                        prop: "endTime",
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "rangedatetimer",
                          startTime: _vm.form.startTime,
                          endTime: _vm.form.endTime,
                          minDate: _vm.minDate,
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(_vm.form, "startTime", $event)
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(_vm.form, "startTime", $event)
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(_vm.form, "endTime", $event)
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(_vm.form, "endTime", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("multi-select2", {
            ref: "multiSelect",
            attrs: {
              searchUrl: _vm.houseSearchUrl,
              headers: _vm.tableHeaders,
              searchParams: _vm.searchParams,
              responseParams: _vm.responseParams,
              responseKey: _vm.responseKey,
              backFill: _vm.form.houseIdList,
              handleData: _vm.handleData,
              maxCount: 5000,
              isShow: _vm.visible,
            },
            on: {
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "houseIdList", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "houseIdList", $event)
              },
              "update:isShow": function ($event) {
                _vm.visible = $event
              },
              "update:is-show": function ($event) {
                _vm.visible = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-select", {
                      attrs: { label: "使用状态", options: _vm.usingStatusOps },
                      model: {
                        value: _vm.searchParams.usingStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "usingStatus", $$v)
                        },
                        expression: "searchParams.usingStatus",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "苑" },
                      model: {
                        value: _vm.searchParams.blockName,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "blockName", $$v)
                        },
                        expression: "searchParams.blockName",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "幢" },
                      model: {
                        value: _vm.searchParams.buildingNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "buildingNumber", $$v)
                        },
                        expression: "searchParams.buildingNumber",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "单元" },
                      model: {
                        value: _vm.searchParams.unit,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "unit", $$v)
                        },
                        expression: "searchParams.unit",
                      },
                    }),
                    _c("v-input", {
                      attrs: { label: "室" },
                      model: {
                        value: _vm.searchParams.room,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "room", $$v)
                        },
                        expression: "searchParams.room",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "房屋类型", options: _vm.houseTypeOps },
                      model: {
                        value: _vm.searchParams.houseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "houseType", $$v)
                        },
                        expression: "searchParams.houseType",
                      },
                    }),
                    _c("v-select", {
                      attrs: { label: "房屋状态", options: _vm.roomStatusOps },
                      model: {
                        value: _vm.searchParams.roomStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "roomStatus", $$v)
                        },
                        expression: "searchParams.roomStatus",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }